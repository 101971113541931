import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/Layout'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Seo from '../components/Seo'
import Review from "../components/Review"

import { rhythm } from '../utils/typography'
import { themeStyles } from '../utils/theme'
import {default as Helmet, baseURL } from '../components/Helmet'

const styles = {
  reviewTitle: {
    fontWeight: 'bold',
    marginTop: rhythm(0.5),
    textAlign: 'right',
  }
}
class ReviewTemplate extends React.Component {
  render() {
    const page = get(this.props, 'data.markdownRemark')

    const slug = get(page, 'fields.slug')
    const title = get(page, 'frontmatter.title')
    const excerpt = get(page, 'excerpt')

    return (
      <Layout location={this.props.location}>
        <Seo
          title={title}
          description={excerpt}
        />
        <Helmet
          description={excerpt}
          title={title}
          type="review"
          path={slug}
        />
        <Header />
        <div css={themeStyles.textPadding}>
          <h2>A 5-Star Review</h2>
          <Review title={title} html={page.html} />
        </div>
        <Footer />
      </Layout>
    )
  }
}

export default ReviewTemplate

export const pageQuery = graphql`
  query Review($id: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`